// @flow
import auth from "./auth";
import ecommerce from "./ecommerce";
import bootcamps from "./bootcamps";
import users from "./users";
import applications from "./applications";

export default {
  auth,
  ecommerce,
  bootcamps,
  users,
  applications,
};
